var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("div", { staticClass: "grid-header-settings" }, [
    _c(
      "div",
      { staticClass: "grid-header-settings__actions" },
      [_vm._t("actions")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grid-header-settings__layout-configuration" },
      [_vm._t("configuration")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }