var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _vm._t("prepend"),
      _vm._v(" "),
      _c("GridHeaderSettings", {
        scopedSlots: _vm._u(
          [
            {
              key: "actions",
              fn: function() {
                return [_vm._t("actions")]
              },
              proxy: true
            },
            {
              key: "configuration",
              fn: function() {
                return [
                  _vm.product_grid_url
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "buttton_save_grid buttton_save_grid--secondary buttton_save_grid--small",
                          attrs: {
                            "aria-label": "Fab button",
                            type: "button",
                            "data-cy": "grid-table-view"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "button__title",
                              on: { click: _vm.copyURL }
                            },
                            [_vm._v("СОХРАНИТЬ СЕТКУ В URL")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.layoutActivators.length > 1
                    ? _vm._l(_vm.layoutActivators, function(activator) {
                        return _c(activator.component, {
                          key: activator.key,
                          tag: "Component",
                          attrs: {
                            selected: _vm.layout === activator.key,
                            "data-cy": activator.dataCy
                          },
                          on: { active: _vm.onLayoutActivate }
                        })
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("Fab", {
                    attrs: { theme: _vm.theme.SECONDARY },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onShowModal.apply(null, arguments)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function(ref) {
                          var color = ref.color
                          return [
                            _c("IconSettings", {
                              attrs: { "fill-color": color }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _vm._t("configuration")
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _vm.isSettingsModal
        ? _c("GridSettingsModalForm", {
            attrs: { "layout-configs": _vm.layoutConfigs },
            on: { close: _vm.onCloseModal, apply: _vm.onApplySettings }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("append")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }